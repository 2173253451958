import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "fullscreen-video" }
const _hoisted_2 = { class: "fullscreen-video__header" }
const _hoisted_3 = { class: "image-container" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "image-container__loading-tint image-container__item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.camera.name) + " ", 1),
      _createElementVNode("button", {
        class: "fullscreen-video__close-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, " ✕ ")
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.open)
        ? (_openBlock(), _createElementBlock("iframe", {
            key: 0,
            class: "image-container__frame image-container__item",
            src: _ctx.frameSrc,
            marginwidth: "0",
            marginheight: "0",
            allowfullscreen: "",
            seamless: "seamless",
            onLoad: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFrameLoad && _ctx.onFrameLoad(...args)))
          }, null, 40, _hoisted_4))
        : _createCommentVNode("", true),
      _createVNode(_Transition, { name: "iframe" }, {
        default: _withCtx(() => [
          (!_ctx.open || !_ctx.loaded)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "image-container__image image-container__item",
                src: _ctx.imgSrc
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "iframe" }, {
        default: _withCtx(() => [
          (_ctx.open && !_ctx.loaded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Загрузка видео... "))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}