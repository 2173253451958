import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "camera-view-history" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: _ctx.allLink,
      target: "_blank",
      class: "camera-link camera-link_full-width"
    }, " Все камеры ", 8, _hoisted_2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cameras, (camera) => {
      return (_openBlock(), _createElementBlock("a", {
        key: camera.id,
        href: camera.link,
        target: "_blank",
        class: "camera-link"
      }, _toDisplayString(camera.name), 9, _hoisted_3))
    }), 128))
  ]))
}