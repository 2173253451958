
import VueTypes from 'vue-types';
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    camera: VueTypes.object.isRequired,
    imgSrc: VueTypes.string.isRequired,
    open: VueTypes.bool.def(false),
  },
  setup(props) {
    const loaded = ref(false);
    const frameSrc = computed(() => props.camera.iframeUrl);

    function onFrameLoad() {
      loaded.value = true;
    }

    return {
      frameSrc,
      loaded,
      onFrameLoad,
    };
  },
});
