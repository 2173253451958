import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "settings" }
const _hoisted_2 = { class: "settings-group settings-group_with-slider" }
const _hoisted_3 = { class: "settings-group__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slider = _resolveComponent("Slider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("h5", { class: "settings-group__header" }, " Размер шрифта подписей камер ", -1)),
        _createVNode(_component_Slider, {
          modelValue: _ctx.fontSize,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fontSize) = $event)),
          class: "settings-group__slider",
          min: 5,
          max: 30,
          "tooltip-placement": "bottom"
        }, null, 8, ["modelValue"])
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "settings-group__comment" }, " Эта настройка хранится локально на устройстве. Если открыть эту страницу в другом браузере или на другом устройстве, то значение будет отличаться. ", -1))
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "settings__spacer" }, null, -1))
  ]))
}