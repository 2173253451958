import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "top-bar__status"
}
const _hoisted_2 = {
  key: 2,
  class: "top-bar__status"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['top-bar', { 'top-bar_error': !_ctx.ok, 'top-bar_stretch': _ctx.closable }])
  }, [
    (!_ctx.menuMode)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "top-bar__menu-button menu-button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggleMenu')))
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("div", { class: "menu-button__stripe" }, null, -1),
          _createElementVNode("div", { class: "menu-button__stripe" }, null, -1),
          _createElementVNode("div", { class: "menu-button__stripe" }, null, -1)
        ])))
      : _createCommentVNode("", true),
    (!_ctx.ok)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, " Соединение разорвано! Ожидаем соединения... "))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])),
    (_ctx.closable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 3,
          class: "top-bar__close-button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
        }, " ✕ "))
      : _createCommentVNode("", true)
  ], 2))
}