import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "root app"
}
const _hoisted_2 = {
  key: 2,
  class: "root loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginScreen = _resolveComponent("LoginScreen")!
  const _component_MenuPanel = _resolveComponent("MenuPanel")!
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_CameraGrid = _resolveComponent("CameraGrid")!

  return (_openBlock(), _createBlock(_Transition, { name: "switch" }, {
    default: _withCtx(() => [
      (!_ctx.authorized)
        ? (_openBlock(), _createBlock(_component_LoginScreen, {
            key: 0,
            class: "root login"
          }))
        : (_ctx.cameras)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_Transition, { name: "slide-out" }, {
                default: _withCtx(() => [
                  (_ctx.menuVisible)
                    ? (_openBlock(), _createBlock(_component_MenuPanel, {
                        key: 0,
                        ok: !_ctx.connectionLost,
                        onClose: _ctx.hideMenu
                      }, null, 8, ["ok", "onClose"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_Transition, { name: "fade" }, {
                default: _withCtx(() => [
                  (_ctx.menuVisible)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "backdrop",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideMenu && _ctx.hideMenu(...args)))
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_TopBar, {
                ok: !_ctx.connectionLost,
                onToggleMenu: _ctx.showMenu
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" Все камеры ")
                ])),
                _: 1
              }, 8, ["ok", "onToggleMenu"]),
              _createVNode(_component_CameraGrid)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, " Загружаем камеры... "))
    ]),
    _: 1
  }))
}