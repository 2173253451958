import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-screen" }
const _hoisted_2 = { class: "login-screen__window" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "login-screen__header" }, " Введите пароль ", -1)),
      _createElementVNode("form", {
        class: _normalizeClass(['input-group', { 'input-group_shake': _ctx.invalid }]),
        onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
      }, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.password) = $event)),
          type: "password",
          class: "input-group__input"
        }, null, 512), [
          [_vModelText, _ctx.password]
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("button", {
          type: "submit",
          class: "input-group__button"
        }, " ▶ ", -1))
      ], 34)
    ])
  ]))
}